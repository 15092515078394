import React, { useState } from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { FiXCircle } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import HubspotForm from '../hubspot/HubspotForm'

function CategoryWidgetAd({ tags }) {
  const [showDialog, setShowDialog] = useState(false)

  const {
    dedicatedSidebarImage,
    hipaaSidebarImage,
    vpsSidebarImage,
  } = useStaticQuery(graphql`{
    dedicatedSidebarImage: file(relativePath: {eq: "kb/advert/dedicated-sidebar.jpg"}) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 250
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    hipaaSidebarImage: file(relativePath: {eq: "kb/advert/hipaa-sidebar.jpg"}) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 250
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    vpsSidebarImage: file(relativePath: {eq: "kb/advert/vps-sidebar.jpg"}) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 250
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
  }`);

  const images = [
    dedicatedSidebarImage,
    hipaaSidebarImage,
    vpsSidebarImage,  
  ];

  const DEDICATED = 'dedicated'
  const VPS = 'vps'
  const HIPAA = 'hipaa'

  /**
   * Object containing the tag name as key and the ad to be displayed as value
   * If no tag is specified the default ad will be VPS
   * const keywords = {tag.slug: DEDICATED|VPS|HIPAA}
   */
  const keywords = {
    hipaa: HIPAA,
    apache: DEDICATED,
    cve: DEDICATED,
    disk: DEDICATED,
    'whm-cpanel': DEDICATED,
    whm: DEDICATED,
    php: DEDICATED,
    python: DEDICATED,
    vpn: DEDICATED,
    private: DEDICATED,
    server: DEDICATED,
    'disk-space': DEDICATED,
    dedicated: DEDICATED,
    parent: DEDICATED,
    linux: VPS,
    cpanel: VPS,
    'ubuntu-16-04': VPS,
    storm: VPS,
    plesk: VPS,
    centos: VPS,
    fedora: VPS,
    ubuntu: VPS,
    debian: VPS,
  }

  let key = VPS
  for (const index in tags) {
    if (keywords[tags[index].slug]) {
      key = keywords[tags[index].slug]
      break
    }
  }

  const alt =
    key === DEDICATED
      ? 'Get 33% off the first 3 months on a new Dedicated server!'
      : key === HIPAA
      ? 'Save 50% for 3 months on HIPAA solutions'
      : 'Get 33% off the first 3 months on a new VPS!'

  if (key === HIPAA) {
    return <AdImage keyword={key} images={images} alt={alt} />
  }

  const hubspotStandardFormID = key === 'DEDICATED' ?
    '7f7e4ff0-01f3-40b5-80d7-1f8392708a89' :
    'f529d134-1f74-4a6a-b67b-835f9408d48f';

  const image = images.find(item => item.name === `${key}-sidebar`);

  return (
    <div className="pt-10 text-center">
      <a
        className="inline-block"
        href="#"
        aria-label={alt}
        onClick={() => setShowDialog(true)}
      >
        {image?.childImageSharp?.gatsbyImageData && (
           <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={alt} />
        )}
      </a>
      <DialogOverlay
        className="z-40"
        style={{ background: 'hsla(0, 100%, 0%, 0.75)' }}
        isOpen={showDialog}
        onDismiss={() => setShowDialog(false)}
      >
        <DialogContent
          className="shadow rounded relative max-w-lg"
          style={{
            width: `100%`,
          }}
          aria-label="modal"
        >
          <button
            className="absolute focus:outline-none"
            onClick={() => setShowDialog(false)}
            aria-label="close"
            style={{ top: `1rem`, right: `1rem` }}
          >
            <FiXCircle />
          </button>
          <HubspotForm cssClass="lw-modal-hubspot-form coupon-sidebar" standardFormID={hubspotStandardFormID} />
        </DialogContent>
      </DialogOverlay>
    </div>
  );
}

function AdImage({ keyword, images, alt }) {
  const image = images.find(item => item.name === `${keyword}-sidebar`);

  return (
    <div className="pt-10 text-center">
      <Link
        className="inline-block"
        to="/solutions/hipaa-compliant-hosting"
        aria-label={alt}
      >
        {image?.childImageSharp?.gatsbyImageData && (
           <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={alt} />
        )}
      </Link>
    </div>
  );
}

CategoryWidgetAd.propTypes = {
  tags: PropTypes.array,
}

export default CategoryWidgetAd
