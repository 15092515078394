import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

function SeriesNavigation({ current, kb, posts }) {
  // sort post nodes by seriesPart
  const nodes = posts?.nodes?.sort((a, b) =>
    a.seriesPart > b.seriesPart ? 1 : -1
  )

  // find current prev and next within nodes
  let prev, next
  nodes.map((post, key) => {
    post.uri = kb ? post.uri.replace('/blog', '') : post.uri
    if (post.uri === current) {
      if (nodes[key - 1]) {
        prev = nodes[key - 1].uri
      }
      if (nodes[key + 1]) {
        next = nodes[key + 1].uri
      }
    }
  })

  return (
    <fieldset>
      <div
        className="w-full p-0 mb-5 text-2xl border-b"
        style={{ color: '#444', borderColor: '#7c8781' }}
      >
        Series Navigation
      </div>
      <span className="series-nav-left">
        {prev && <Link to={prev}>&lt;&lt; Previous Article</Link>}
      </span>
      <span className="series-nav-right">
        {next && <Link to={next}>Next Article &gt;&gt;</Link>}
      </span>
    </fieldset>
  )
}

SeriesNavigation.propTypes = {
  current: PropTypes.string.isRequired,
  kb: PropTypes.bool,
  posts: PropTypes.object,
}

export default SeriesNavigation
