import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

function SeriesLinks({ current, name, uri, kb, posts }) {
  uri = kb ? uri.replace('/blog', '/kb') : uri

  // sort post nodes by seriesPart
  const nodes = posts?.nodes?.sort((a, b) =>
    a.seriesPart > b.seriesPart ? 1 : -1
  )

  return (
    <div className="series-box">
      <div className="series-heading">
        <Link to={uri}>{name}</Link>
      </div>
      {nodes.length && (
        <ol className="series-list">
          {nodes.map(post => {
            post.uri = kb ? post.uri.replace('/blog', '') : post.uri

            if (post.uri === current)
              return (
                <li key={post.uri} className="series-list-item current">
                  {post.title}
                </li>
              )

            return (
              <li key={post.uri} className="series-list-item">
                <Link to={post.uri}>{post.title}</Link>
              </li>
            )
          })}
        </ol>
      )}
    </div>
  )
}

SeriesLinks.propTypes = {
  current: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  kb: PropTypes.bool,
  posts: PropTypes.object,
}

export default SeriesLinks
