import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './kbPost.module.css'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import StructuredData from '../components/structured-data'
import SubscribeWidget from '../components/subscribe-widget/subscribe-widget'
import CategoryWidget from '../components/category-widget/category-widget'
import CategoryWidgetAd from '../components/category-widget/category-widget-ad'
import QuestionsWidget from '../components/questions-widget/questions-widget'
import SearchForm from '../components/search/search-form'
import SubscribeHorizontalWidget from '../components/subscribe-widget/subscribe-horizontal-widget'
import ReferFriendWidget from '../components/refer-friend-widget/refer-friend-widget'
import RelatedArticle from '../components/kb/related-article'
import PostHeader from '../components/kb/post-header'
import ShareSocialIcons from '../components/kb/share-social-icons'
import LatestArticles from '../components/kb/latest-articles'
import useSiteMetadata from '../hooks/use-site-metadata'
import parseHtml from '../components/blocks/parser'
import SeriesLinks from '../components/series/series-links'
import SeriesNavigation from '../components/series/series-navigation'

const KBPostTemplate: React.FC<KBPostTemplateProps> = ({ data, location }) => {
  const siteMetadata = useSiteMetadata()
  const { post, relatedArticle } = data
  const postURL = `${siteMetadata.siteUrl}${location.pathname}`
  const { tags, series } = post

  const contentNode = post.lwContent.isGutenberg ? (
    <div className={`entry ${styles.content}`}>
      {parseHtml(post.lwContent.content)}
    </div>
  ) : (
    <div
      className={`entry ${styles.content}`}
      dangerouslySetInnerHTML={{ __html: post.lwContent.content }}
    />
  )

  return (
    <Layout>
      <Seo post={post} />
      <StructuredData pageType="kbArticle" post={post} />
      <SearchForm kb={true} />
      <div className="container kb-post grid my-5 lg:grid-cols-12 lg:gap-x-20">
        <div className="lg:col-span-7 xl:col-span-8 pb-2">
          <div
            className="relative pb-2 mb-2 border-b"
            style={{ borderBottomColor: '#d9d9d9' }}
          >
            <ShareSocialIcons postTitle={post.title} postURL={postURL} />
            <div className="relative mt-4 z-20 knowledgeBaseTextColor">
              <PostHeader post={true} {...post} />
            </div>
            {post.featuredImage &&
              post.featuredImage.node &&
              post.featuredImage.node.localFile && (
                <GatsbyImage
                  image={
                    post.featuredImage.node.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  className="top-0 left-0 w-full h-full z-10"
                  style={{ position: `absolute` }}
                />
              )}
          </div>
          {series?.nodes[0] && (
            <SeriesLinks
              kb={true}
              current={post.uri.replace('/blog', '')}
              {...series.nodes[0]}
            />
          )}
          <div className="text-grey-darker mb-4 text-sm md:text-base">
            Reading Time: {post.readingTime}{' '}
            {post.readingTime === '< 1' ? 'minute' : 'minutes'}
          </div>
          {contentNode}
          {series?.nodes[0] && (
            <SeriesNavigation
              kb={true}
              current={post.uri.replace('/blog', '')}
              {...series.nodes[0]}
            />
          )}
          <ShareSocialIcons
            className="my-5"
            postTitle={post.title}
            postURL={postURL}
          />
          {relatedArticle && <RelatedArticle articles={relatedArticle.nodes} />}
          <div
            className="pt-4 pb-6 mb-4 border-b"
            style={{ borderBottomColor: '#d9d9d9' }}
          >
            {post.author?.node &&
              post.author.node.id !== 'dXNlcjo0NA==' && ( // If author is Liquid Web, don't show author box
                <div
                  className="py-2 px-5"
                  style={{ backgroundColor: '#F5F5F5', color: '#444' }}
                >
                  <div className="flex items-center mb-6">
                    {post.author.node.avatar.imageFile?.childImageSharp && (
                      <GatsbyImage
                        image={post.author.node.avatar.imageFile.childImageSharp?.gatsbyImageData}
                        alt={`Avatar for ${post.author.node.name}`}
                        className="rounded-full flex-shrink-0 border my-5 mr-2" />
                    )}
                    <div className="ml-4">
                      <h2 className="font-bold text-base">
                        About the Author: {post.author.node.name}
                      </h2>
                      <p
                        className="text-sm"
                        dangerouslySetInnerHTML={{
                          __html: post.author.node.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
          <ReferFriendWidget className="text-center" knowledgeBase={true} />
        </div>
        <div className="lg:col-span-5 xl:col-span-4 my-4">
          <div className="p-4" style={{ backgroundColor: '#f4f4f4' }}>
            <SubscribeWidget className="knowledgeBase" isKB />
            <CategoryWidgetAd tags={tags.nodes} />
            <CategoryWidget className="mt-10" knowledgeBase={true} />
            <QuestionsWidget className="my-10" />
          </div>
        </div>
      </div>
      <LatestArticles />
      <SubscribeHorizontalWidget knowledgeBase={true} />
    </Layout>
  )
}

type KBPostTemplateProps = {
  location: Record<string, unknown>
  data: {
    post: {
      title: string
      lwContent: {
        isGutenberg: boolean
        content?: string
      }
      slug: string
      uri: string
      date: string
      readingTime: string
      modified: string
      featuredImage: {
        node?: {
          altText: string
          publicURL: string
          localFile: { childImageSharp: { fluid: FluidObject } }
        }
      }
      author: {
        node?: {
          name: string
          id: string
          description: string
          uri: string
          avatar: {
            url: string
            imageFile: { childImageSharp: { fixed: FixedObject } }
          }
        }
      }
      seo: any
      categories: {
        nodes?: Array<{
          name: string
          uri: string
        }>
      }
      tags: {
        nodes?: Array<{
          name: string
          uri: string
          slug: string
        }>
      }
      series: {
        nodes?: Array<{
          name: string
          uri: string
          posts: {
            nodes?: Array<{
              title: string
              uri: string
              seriesPart: number
            }>
          }
        }>
      }
    }
    relatedArticle: {
      nodes: Array<{
        title: string
        uri: string
      }>
    }
  }
}

export const pageQuery = graphql`
  query GET_KBPOST($id: String!, $categoryID: String) {
    post: wpKnowledgeBase(id: { eq: $id }) {
      title
      lwContent {
        isGutenberg
        content
      }
      slug
      uri
      date
      modified
      readingTime
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      author {
        node {
          name
          id
          description
          uri
          avatar {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  width: 96
                  height: 96
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FIXED
                )
              }
            }
          }
        }
      }
      categories: knowledgeBaseCategories {
        nodes {
          name
          uri
        }
      }
      tags: knowledgeBaseTags {
        nodes {
          name
          uri
          slug
        }
      }
      series: seriesAll {
        nodes {
          name
          uri
          posts: knowledgeBases {
            nodes {
              title
              uri
              seriesPart
            }
          }
        }
      }
      seo {
        ...postSeoFields
      }
      ...ReusableBlockKBFragment
    }
    relatedArticle: allWpKnowledgeBase(
      filter: {
        knowledgeBaseCategories: {
          nodes: { elemMatch: { id: { eq: $categoryID } } }
        }
        id: { ne: $id }
      }
      sort: { order: DESC, fields: date }
      limit: 6
    ) {
      nodes {
        title
        uri
      }
    }
  }
`

export default KBPostTemplate
